import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, HostBinding, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { matSelectAnimations } from '@angular/material/select';
import { Router } from '@angular/router';
import { FlatTreeApi } from '@core/helpers/flat-tree/flat-tree-api.helper';
import { IFlatTreeItemProps } from '@core/helpers/flat-tree/flat-tree-api.interfaces';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';
import { BusinessStructureService } from '@shared/business-structure/services/business-structure.service';
import { BusinessStructureFacade } from '@shared/business-structure/store/business-structure.facade';
import { BusinessUnitSearchResult } from '@shared/business-structure/types/bu-search-result.type';
import { Client } from '@shared/clients/models/client.model';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { GovernanceService } from '@shared/governance/services/governance.service';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';
import { UserRolesEnum } from '@shared/users-module/types/user-roles.enum';
import { ToastrService } from 'ngx-toastr';
import { combineLatestWith, debounce, distinctUntilChanged, interval, map, Observable, of, switchMap, takeWhile, zip } from 'rxjs';

export type BuTreeNode = {
  id: string;
  p_id?: string;

  label: string;
  loading?: boolean;
  isExpandable?: boolean;
  isActive?: boolean;

  client: Client;
  business_unit_id?: number;
  business_structure?: string;
};
export type BuTreeNodeItem = BuTreeNode & IFlatTreeItemProps;

@Component({
  selector: 'business-unit-navigation',
  templateUrl: './business-unit-navigation.component.html',
  styleUrls: ['./business-unit-navigation.component.scss'],
  animations: [matSelectAnimations.transformPanel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessUnitNavigationComponent implements OnInit {
  private __clientFacade = inject(ClientFacade);
  private __router = inject(Router);
  private __governanceService = inject(GovernanceService);
  private __businessStructureFacade = inject(BusinessStructureFacade);
  private __businessStructureService = inject(BusinessStructureService);
  private __location = inject(Location);
  private __loaderService = inject(GlobalLoaderService);
  private __destroyRef = inject(DestroyRef);
  private __toastrService = inject(ToastrService);

  private __clients: Client[] = [];
  private __$currentBusinessUnit = signal<BuTreeNode>(undefined);
  private __$currentClient = signal<Client>(this.__clientFacade.selectedClient);
  private __$path = computed<BuTreeNode[]>(() => this.__getPath());

  $isDisabled = signal(false);
  $pathInfo = computed(() =>
    this.__$path()
      .map((el) => el.label)
      .join(' / ')
  );
  $pathText = computed(() => {
    if (!this.__$currentClient()) return 'Choose a client';
    if (!this.__$path()?.length || this.__$path().length === 1) return this.__$currentClient().name;
    return this.$pathInfo();
  });
  $showOverlay = signal(false);
  vscrollTreeApi = new FlatTreeApi<BuTreeNode>().setOptions({
    key: ['id'],
    parentKey: ['p_id']
  });
  searchControl = new FormControl<string>('');
  private __$searchResultQuery = toSignal(this.__getQuerySignalLogic());
  $searchValid = computed(() => {
    const l = this.__$searchResultQuery()?.query?.length || 0;
    return l === 0 || l >= 2;
  });
  $isSearchMode = computed(() => {
    return (this.__$searchResultQuery()?.query?.length || 0) > 2;
  });
  $nodes = computed<BuTreeNodeItem[]>(() => this.__$searchResultQuery()?.nodes || (this.vscrollTreeApi.nodes() as BuTreeNodeItem[]));

  @HostBinding('class')
  get hostClass(): string {
    const cls: string[] = [];
    !this.__clients?.length && cls.push('d-none');
    return cls.join(' ');
  }

  ngOnInit(): void {
    this.__governanceService.disableBusinessStructureNavigation$
      .pipe(takeUntilDestroyed(this.__destroyRef))
      .subscribe((event) => this.$isDisabled.set(event));

    this.__clientFacade.selectedClient$
      .pipe(
        takeUntilDestroyed(this.__destroyRef),
        combineLatestWith(this.__clientFacade.clients$, this.__clientFacade.selectedBusinessUnit$)
      )
      .subscribe(([currentClient, clients, currentBusinessUnit]) => {
        this.__loaderService.dismiss('selected-client-change');
        this.__clients = clients.list?.filter((client) => client.role !== UserRolesEnum.ANY || client.is_requested);
        this.__$currentClient.set(currentClient);
        this.__$currentBusinessUnit.set(currentBusinessUnit ? this.__buToBuNode(currentBusinessUnit, currentClient) : undefined);

        const nodes = this.__clients.map((client) => {
          return {
            id: client.slug,
            label: client.name,
            client: client,
            isExpandable: !!client.has_children,
            isActive: this.__clientFacade.hasGlobalAccess(client)
          };
        });

        this.vscrollTreeApi.build(nodes);

        if (this.__clientFacade.selectedBusinessUnit) {
          const clientNode = this.vscrollTreeApi.nodesList
            .filter((node) => !node.business_unit_id)
            .find((node) => node.client?.slug === this.__$currentClient()?.slug);
          clientNode && !clientNode.$expanded() && this.__loadBuNodes(clientNode);
        }
      });
  }

  private __getPath(): BuTreeNode[] {
    const parents = this.vscrollTreeApi.getParents(this.__$currentBusinessUnit());
    const currentNode = this.__$currentBusinessUnit();
    const path = [...(parents?.list || []), currentNode];
    if (!parents || !parents.list?.length) {
      return [
        {
          id: this.__$currentClient()?.slug,
          label: this.__$currentClient()?.name,
          client: this.__$currentClient()
        }
      ];
    }
    return path.map((node) => ({
      id: node.id,
      label: node.label,
      client: node.client
    }));
  }

  private __getQuerySignalLogic(): Observable<{ query: string; nodes: BuTreeNodeItem[] }> {
    return this.searchControl.valueChanges.pipe(
      takeUntilDestroyed(this.__destroyRef),
      map((q) => q.trim().toLowerCase()),
      debounce((q) => (!q ? of(null) : interval(500))),
      distinctUntilChanged(),
      switchMap((q) => zip(of(q), q?.length > 2 ? this.__businessStructureService.getBusinesStructureSearch(q) : of(undefined))),
      map(([query, nodes]) => ({ query, nodes: nodes ? this.__parseSearchResultToNodes(nodes) : undefined }))
    );
  }

  private __loadBuNodes(node: BuTreeNodeItem): void {
    if (node.$hasChildren()) {
      this.vscrollTreeApi.toggle(node);
      return;
    }

    node.loading = true;

    this.__businessStructureFacade.businessStructure$
      .pipe(
        takeUntilDestroyed(this.__destroyRef),
        takeWhile((d) => !d.data[node.client.slug]?.flattenedDivisions, true)
      )
      .subscribe((d) => {
        const clientSlug = node.client.slug;

        if (!d.data[clientSlug]) {
          return;
        }

        const children = d.data[clientSlug]?.flattenedDivisions || [];
        const nodes = this.__buListToNodeList(children, node);

        node.loading = false;

        this.vscrollTreeApi.expandAll(node, ...nodes);
      });

    this.__businessStructureFacade.getBusinessStructure(node.client);
  }

  private __buListToNodeList(businessUnits: BusinessUnit[], node: BuTreeNode): BuTreeNode[] {
    return businessUnits.map((child) => {
      return {
        id: String(child.id),
        p_id: String(child.parent_business_unit_id || node.id),
        label: child.name,
        isExpandable: !!child.divisions?.length,
        isActive: child.is_active,
        client: node.client,
        business_unit_id: child.id
      };
    });
  }

  private __buToBuNode(bu: BusinessUnit, client: Client): BuTreeNode {
    return {
      id: String(bu.id),
      p_id: bu.parent_business_unit_id ? String(bu.parent_business_unit_id) : undefined,
      label: bu.name,
      isExpandable: !!bu.divisions?.length,
      client,
      business_unit_id: bu.id
    };
  }

  private __navigateAfterClientSelection(client: Client, businessUnit: BusinessUnit): void {
    const previousClientSlug = this.__$currentClient()?.slug;

    if (client) {
      if (this.__router.url.includes(`${APP_ROUTES.part.admin}/${APP_ROUTES.part.clients}`)) {
        this.__router.navigate(APP_ROUTES.accountsV1.overview(client?.slug));
      } else if (previousClientSlug && previousClientSlug !== client?.slug) {
        const url = this.__location.path().replace(`/${previousClientSlug}/`, `/${client.slug}/`);
        this.__router.navigateByUrl(url, { state: { client, businessUnit } });
      }
    }
  }

  private __parseSearchResultToNodes(nodes: BusinessUnitSearchResult[]): BuTreeNodeItem[] {
    return nodes.map((node) => {
      return {
        id: String(node.id),
        label: node.name,
        client: this.__clients.find((c) => c.slug === node.slug),
        business_unit_id: node.business_structure === node.name ? null : node.id,
        business_structure: node.business_structure,
        $expanded: signal(false),
        $level: signal(0),
        isActive: true
      };
    });
  }

  isSelected(node: BuTreeNode): boolean {
    const client = this.__$currentClient();
    const businessUnit = this.__$currentBusinessUnit();
    return !businessUnit ? client?.slug === node.id : businessUnit?.id === node.id;
  }

  nodeToggle(node: BuTreeNodeItem): void {
    if (node.$expanded()) {
      this.vscrollTreeApi.collapse(node);
      return;
    }

    this.__loadBuNodes(node);
  }

  close(): void {
    this.$showOverlay.set(false);
  }

  loadClientBuTreeAndSelectBu(bu: BuTreeNode): void {
    const buClient = this.__clients.find((c) => c.slug === bu.client.slug);
    const buClientNode = this.vscrollTreeApi.nodesList.find((node) => node.client.slug === buClient?.slug);
    const buClientNodeChildren = buClientNode ? this.vscrollTreeApi.getChildren(buClientNode) : undefined;

    if (!buClientNode) {
      this.__toastrService.error('Client not found in the tree');
      return;
    }

    this.searchControl.setValue('');

    if (buClientNodeChildren?.list?.length) {
      this.vscrollTreeApi.expand(buClientNode);
      this.selectBu(bu);
      return;
    }

    if (this.__businessStructureFacade.businessStructure?.data?.[bu.client.slug]?.flattenedDivisions?.length) {
      const children = this.__businessStructureFacade.businessStructure.data[buClient?.slug]?.flattenedDivisions || [];
      const nodes = this.__buListToNodeList(children, buClientNode);
      this.vscrollTreeApi.expandAll(buClientNode, ...nodes);
      this.selectBu(bu);
      return;
    }

    buClientNode.loading = true;

    this.__businessStructureFacade.businessStructure$
      .pipe(
        takeUntilDestroyed(this.__destroyRef),
        takeWhile((d) => !d.data[buClientNode.client.slug]?.flattenedDivisions, true)
      )
      .subscribe((d) => {
        const clientSlug = buClientNode.client.slug;

        if (!d.data[clientSlug]) {
          return;
        }

        const children = d.data[clientSlug]?.flattenedDivisions || [];
        const nodes = this.__buListToNodeList(children, buClientNode);

        buClientNode.loading = false;

        this.vscrollTreeApi.expandAll(buClientNode, ...nodes);

        this.selectBu(bu);
      });

    this.__businessStructureFacade.getBusinessStructure(buClientNode.client);
  }

  selectBu(node: BuTreeNode): void {
    if (!node) {
      return;
    }

    const isClient = !node.business_unit_id;

    let client: Client;
    let businessUnit: BusinessUnit = null;

    if (isClient) {
      client = node.client;
    } else {
      client = this.__clients.find((c) => c.slug === node.client.slug);
      businessUnit = this.__businessStructureFacade.businessStructure?.data?.[client.slug]?.flattenedDivisions?.find(
        (b) => b.id === node.business_unit_id
      );
    }

    if (this.__router.url.includes(APP_ROUTES.part.business_structure)) {
      if (client?.id === this?.__$currentClient()?.id) {
        this.$showOverlay.set(false);
        return;
      }
      this.__navigateAfterClientSelection(client, businessUnit);
    } else {
      let unsubscribe = false;
      this.__clientFacade.clientAndBusinessUnit$.pipe(takeWhile(() => !unsubscribe)).subscribe(([selectedClient]) => {
        selectedClient && (unsubscribe = true);
        this.__navigateAfterClientSelection(client, businessUnit);
      });
      this.__loaderService.create('selected-client-change');
      this.__clientFacade.setSelectedClientAndBusinessUnit(client, businessUnit);
    }

    this.$showOverlay.set(false);
  }
}
