<div class="octra-dropdown__trigger" cdkOverlayOrigin #trigger="cdkOverlayOrigin" [ngClass]="{ disabled: $isDisabled() }">
  <span class="bu-path" [matTooltip]="$pathInfo()">
    {{ $pathText() }}
  </span>
  <div class="w-8 w-min-8"></div>
  <button mat-icon-button mat-small-button (click)="$showOverlay.set(!$showOverlay())">
    <mat-icon>expand_more</mat-icon>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="octra-dropdown__overlay"
  cdkConnectedOverlayPanelClass="octra-dropdown__panel"
  [cdkConnectedOverlayDisposeOnNavigation]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="$showOverlay()"
  (backdropClick)="close()"
>
  <div [@transformPanel]="'showing'">
    <div class="octra-input mat-input-s mb-4" [ngClass]="{ 'mat-form-field--invalid': !$searchValid() }">
      <mat-icon prefix>search</mat-icon>
      <input type="text" placeholder="Search" [formControl]="searchControl" />
    </div>
    <vscroll-tree [api]="vscrollTreeApi" [nodes]="$nodes()" [itemSize]="32" class="h-min-500">
      <ng-template #template let-node let-api="api">
        <div
          class="octra-tree__node"
          [ngStyle]="{ '--level': $isSearchMode() ? 0 : node.$level() }"
          [ngClass]="{
            'octra-tree__node--selected': isSelected(node),
            'octra-tree__node--expanded': node.$expanded(),
            'octra-tree__node--disabled': !node.isActive
          }"
          [matTooltip]="$isSearchMode() ? node.business_structure : ''"
          [matTooltipPosition]="'above'"
        >
          @if (!$isSearchMode()) {
            @for (_ of node.$levels(); track _) {
              <div class="octra-tree__node__level" [ngStyle]="{ '--level': _ }"></div>
            }
          }
          @if (node.loading) {
            <mat-spinner class="octra-tree__loader" diameter="20" />
          } @else {
            @if (!$isSearchMode()) {
              @if (node.isExpandable) {
                <mat-icon class="octra-tree__toggle" (click)="nodeToggle(node)">expand_more</mat-icon>
              } @else {
                <div class="octra-tree__toggle"></div>
              }
            }
          }
          <div
            class="octra-tree__content"
            [ngClass]="{ 'cursor-pointer': node.isActive }"
            (click)="node.isActive && ($isSearchMode() ? loadClientBuTreeAndSelectBu(node) : selectBu(node))"
          >
            <span class="text-truncate px-4">{{ node.label }}</span>
          </div>
        </div>
      </ng-template>
    </vscroll-tree>
  </div>
</ng-template>
